import { faArrowLeft, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuItem, Select } from '@material-ui/core'
import * as React from 'react'
import { selectAppAccountDisplayName } from '../../AppAccount/_selectors'
import { selectUserAppAcctId } from '../../Auth/_selectors'
import { Organization } from '../../Issue/_reducer'
import { selectUserEmail } from '../../Issue/_selectors'
import { Button } from '../../Shared/Buttons'
import { Spacer } from '../../Shared/Spacer'
import { useAppSelector } from '../../_utils/reactReduxHooks'
import { DashboardType, DashboardView } from './DashboardModels'
import classes from '../../Admin/AdminConsolePage.module.scss'
import { icoFetch } from '../../_utils/fetchUtils'
import { useIsMounted } from '../../_utils/hooks'

export const DashboardHeader = ({
  currentType,
  currentView,
  setCurrentView,
  isReordering,
  isAdmin,
  setReorder,
  setHeaderAction,
  onChangeType,
  onReorderSave,
  onReorderCancel,
  onManageSelection,
  selectedOrgList,
  userDisplayName,
  userSelectingOrg,
}: {
  currentType: DashboardType
  currentView: DashboardView
  setCurrentView: (view: DashboardView) => void
  isReordering: boolean
  isAdmin: boolean
  setReorder: (isReorder: boolean) => void
  setHeaderAction: (action: string) => void
  onChangeType: (isChangeMode: DashboardType) => void
  onReorderSave: () => void
  onReorderCancel: () => void
  onManageSelection: () => void
  selectedOrgList?: Organization[]
  userDisplayName: string
  userSelectingOrg: () => void
}) => {
  const [userPortalAdmin, setUserPortalAdmin] = React.useState(false)

  const appAccountId = useAppSelector(selectUserAppAcctId)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const [width, setWidth] = React.useState<number>(window.innerWidth)

  const isSmall = width <= 996

  const displayName = useAppSelector((state) =>
    selectAppAccountDisplayName(state, appAccountId)
  )

  const isMounted = useIsMounted()

  const userEmail = useAppSelector((state) => selectUserEmail(state))

  const dashboardDisplayName = displayName ? displayName : userEmail

  React.useEffect(() => {
    const getPickListValues = async (): Promise<any> => {
      icoFetch('/api/User/UserJson')
        .then((response) => {
          if (response.ok && isMounted()) return response.json()
        })
        .then((value) => {
          if (!value) return
          const permissionList = value.UserPermissions?.split(',')

          const adminConsolePermission = permissionList.find(
            (permission: string) => {
              return permission.trim() === 'Issues Admin Dashboard'
            }
          )

          setUserPortalAdmin(!!adminConsolePermission)
        })
        .catch((e) => console.log(e))
    }
    getPickListValues()
  }, [isMounted])

  const reorderHeader = (
    <>
      <div style={{ fontSize: '20px' }}>Reorder Tiles</div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            height: '100%',
            userSelect: 'none',
            alignItems: 'center',
          }}
          onClick={() => onReorderCancel()}
        >
          <Button color="standard">Cancel</Button>
        </div>
        <Spacer />
        <Spacer />
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onReorderSave()
            //Send new layout to backend
          }}
        >
          <Button>Save</Button>
        </div>
        <Spacer />
      </div>
    </>
  )

  const adminHeaderSection = (
    <div
      style={{
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',

        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: '10px',
      }}
    >
      <Spacer />
      <div
        onClick={userSelectingOrg}
        style={{
          display: 'flex',
          alignItems: 'center ',
          cursor: 'pointer',
          color: '#3e91c5',
        }}
      >
        Organization:
        {selectedOrgList?.length === 1 && selectedOrgList[0]?.OrgName}
        {selectedOrgList &&
          selectedOrgList?.length > 1 &&
          `${selectedOrgList?.length} organizations selected`}
        <Spacer />
        <FontAwesomeIcon
          icon={faEdit}
          size={'sm'}
          style={{ color: '#3e91c5' }}
        />
      </div>
    </div>
  )

  const standardHeader = (
    <>
      <div
        style={{
          display: 'flex',

          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {userPortalAdmin && (
          <Select
            name="IssueOrgTeam"
            onChange={(evt) => {
              setHeaderAction('None')
              onChangeType(evt.target.value as DashboardType)
            }}
            disabled={!isAdmin}
            style={{ width: '200px' }}
            value={currentType}
          >
            <MenuItem key={'user'} value={'User'}>
              User View
            </MenuItem>
            <MenuItem key={'admin'} value={'Admin'}>
              Organization View
            </MenuItem>
          </Select>
        )}

        {currentType === 'Admin' ? (
          <>{adminHeaderSection}</>
        ) : (
          <div
            style={{
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginLeft: '10px',
            }}
          >
            User: {dashboardDisplayName}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
            marginLeft: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',

              color: '#3e91c5',
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
            onClick={() => {
              setReorder(true)
              setHeaderAction('None')
            }}
          >
            Reorder
          </div>
          <Spacer />
          <Spacer />
          <div
            style={{ cursor: 'pointer', color: '#3e91c5' }}
            onClick={() => onManageSelection()}
          >
            Manage Selections
          </div>
        </div>
      </div>
    </>
  )

  const panelHeader = (
    <div className={classes.PanelHeader}>
      <div className={classes.OrganizationManagementHeaderBackButtonContainer}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{ cursor: 'pointer' }}
          size="1x"
          onClick={() => {
            setCurrentView('Dashboard')
          }}
        />
      </div>
      <div
        className={classes.OrganizationManagementHeaderText}
        style={{ marginLeft: '-50px', display: 'flex' }}
      >
        <div>
          {currentView === 'IssuePanel' && 'Issues Panel for Organization:'}{' '}
        </div>
        <div>
          {currentView === 'TaskPanel' && 'Task Panel for Organization:'}{' '}
        </div>
        <div>
          {currentView === 'UserTaskPanel' && 'Task Panel for My Issues'}{' '}
        </div>
        <Spacer />
        {currentView !== 'UserTaskPanel' && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#3e91c5',
                cursor: 'pointer',
              }}
              onClick={userSelectingOrg}
            >
              {selectedOrgList?.length === 1 && selectedOrgList[0]?.OrgName}
              {selectedOrgList &&
                selectedOrgList?.length > 1 &&
                `${selectedOrgList?.length} organizations selected`}

              <Spacer />
              <FontAwesomeIcon
                style={{ color: '#3e91c5' }}
                icon={faEdit}
                size={'sm'}
              />
            </div>{' '}
          </>
        )}
      </div>
    </div>
  )

  const getHeader = () => {
    if (
      currentView === 'IssuePanel' ||
      currentView === 'TaskPanel' ||
      currentView === 'UserTaskPanel'
    ) {
      return panelHeader
    }
    if (currentView === 'Dashboard') {
      return (
        <div
          style={{
            width: '100%',
            height: '5rem',
            borderBottom: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            zIndex: 3,
            backgroundColor: 'white',
          }}
        >
          {isReordering ? reorderHeader : standardHeader}
        </div>
      )
    }
  }

  return <>{getHeader()}</>
}
